export const SYSTEM_COLORS = {
    PRIMARY: '#66b1f2',
    PRIMARY_SHADE: '#499de5',
    SECONDARY: '#305473',
    SECONDARY_SHADE: '#4f708c',
    TERTIARY: '#f2f0d0',
    TERTIARY_SHADE: '#e7e5c7',
	BACKGROUND: '#383839',
	BACKGROUND_2: '#f3f3f1',
	ERROR: '#EE0000',
	GRAY: '#7A766D',
	GRAY_10: '#DFDFD8',
	GRAY_SHADE: '#302E28',
	LIGHT_GRAY: '#A9A59C',
	LIGHT_GRAY_30: '#D2CFC7',
	WHITE: '#FFFFFF',
	TRANSPARENT_BORDER: 'rgba(48, 46, 40, 0.1)'
};
